import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import {Globals} from 'src/app/global-data';

@Component({
  selector: 'app-footer-movil',
  templateUrl: './footer-movil.component.html',
  styleUrls: ['./footer-movil.component.scss'],
  providers: [Globals]
})
export class FooterMovilComponent implements OnInit {
  navIsCms = false;

  constructor(private router: Router, public globals: Globals) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const ev: any = event;
        this.navIsCms = ev.url.includes('/cms');
      });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

  mailto() {
    var email = 'instituto@benengeli.com';
    location.href = 'mailto:' + email;
  }

  openInMaps() {
    var link ='https://goo.gl/maps/1YMCZF1Us9FGejHD7';
    window.open(link, "_blank");
  }
}
