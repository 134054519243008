<!-- <sl-input
  name="busqueda"
  placeholder="buscar un curso"
  size="small"
  (slChange)="buscar($event)"
>
  <sl-icon name="search" slot="prefix" style="margin-left: 5px;"></sl-icon>
</sl-input> -->

<!-- <form class="example-form">
  <mat-form-field class="example-full-width" color="accent">
    <span matPrefix><mat-icon matSuffix  color="accent">search</mat-icon></span>
    <input type="tel" matInput placeholder="Buscar un curso"  color="accent">
  </mat-form-field>
</form> -->


<form action="">
  <input type="text" name="busqueda" placeholder="buscar" [(ngModel)]="busqueda" (keyup.enter)="buscar($event)">
  <i class="fa fa-search" (click)="buscar(null)"></i>
</form>
