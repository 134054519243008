<!-- header-nav -->
<app-nav></app-nav>
<app-nav-movil></app-nav-movil>

<!-- content -->
<div [class.content]="navIsNotLandingPage" role="main">
  <router-outlet></router-outlet>
</div>

<!-- footer -->
<app-footer></app-footer>
<app-footer-movil></app-footer-movil>
