import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { NavMovilComponent } from './nav-movil/nav-movil.component';
import { FooterMovilComponent } from './footer-movil/footer-movil.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { CoreService } from './core.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AliadosComponent } from './aliados/aliados.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule
  ],
  declarations: [
    NavComponent,
    FooterComponent,
    NavMovilComponent,
    FooterMovilComponent,
    BuscadorComponent,
    NotFoundComponent,
    SpinnerComponent,
    AliadosComponent,
  ],
  exports: [
    NavComponent,
    FooterComponent,
    NavMovilComponent,
    FooterMovilComponent,
    BuscadorComponent,
    SpinnerComponent,
    AliadosComponent,
  ],
  providers: [CoreService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
