<h1>Site Map</h1>
<div class="container">
  <div *ngFor="let routeInst of routes | keyvalue" class="routes">
    <h3>{{ routeInst.key }}</h3>
    <ul *ngFor="let r of routeInst.value">
      <li>
        <a [routerLink]="r.url">{{ r.name }}</a>
      </li>
    </ul>
  </div>
</div>
