export const informacionDeContacto = {
  telefono: '(81) 1977 2764',
  correo: 'instituto@benengeli.com',
  direccion: {
    calle: 'Calle Padre Mier 1240',
    colonia: 'Centro, Monterrey, Nuevo Leon',
    cp: 'C.P. 64000'
  }
};

// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  informacionDeContacto = informacionDeContacto;
}
