<div style="display: block" [style.width]="width" class="slides">
  <slideshow
    #slideshow
    (onClick)="testEvent($event)"
    [imageUrls]="imageUrls"
    [height]="height"
    [minHeight]="minHeight"
    [arrowSize]="arrowSize"
    [showArrows]="showArrows"
    [disableSwiping]="disableSwiping"
    [autoPlay]="autoPlay"
    [stopAutoPlayOnSlide]="stopAutoPlayOnSlide"
    [debug]="debug"
    [backgroundSize]="backgroundSize"
    [backgroundPosition]="backgroundPosition"
    [backgroundRepeat]="backgroundRepeat"
    [showDots]="showDots"
    [dotColor]="dotColor"
    [showCaptions]="showCaptions"
    [captionColor]="captionColor"
    [captionBackground]="captionBackground"
    [lazyLoad]="lazyLoad"
    [hideOnNoSlides]="hideOnNoSlides"
    [fullscreen]="fullscreen"
  >
  </slideshow>
</div>
<div class="container">
  <h1 @fadeInOut class="mensaje-institucional">
    El Instituto Cide Hamet Benengeli avalado por la secretaria de educación es
    especialista en la profesionalización de la seguridad con énfasis en el
    desarrollo humano, ofreciendo cursos, diplomados y talleres en seguridad,
    protección y desarrollo humano integral.
  </h1>
  <button class="mas-info-boton" mat-raised-button color="primary" routerLink="/nosotros" role="button" tabindex="0" aria-label="mas informacion">Más Información</button>
</div>
<app-aliados></app-aliados>
