import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
})
export class SiteMapComponent implements OnInit {
  routes = {
    Home: [
      { name: 'Inicio', url: '/home' },
      { name: 'Terminos y Condiciones', url: '/terminos-condiciones' },
      { name: 'Aviso de privacidad', url: '/aviso-privacidad' },
      { name: 'Site Map', url: '/sitemap' },
    ],
    Nosotros: [
      { name: 'Nosotros', url: '/nosotros' },
      { name: 'Instalaciones', url: '/nosotros/instalaciones' },
    ],
    Cursos: [
      { name: 'Cursos y Diplomados', url: '/cursos' },
      { name: 'Detalle de Curso', url: '/cursos/detalle/5Bz1J7BYXrg090N9AYXF' },
      {
        name: 'Resultados de Busqueda',
        url: '/cursos/detalle/5Bz1J7BYXrg090N9AYXF',
      },
      { name: 'Registro Rapido', url: '/cursos/registroRapido' },
    ],
    Eventos: [
      { name: 'Eventos y Redes Sociales', url: '/eventos' },
      { name: 'Detalle de Evento', url: '/eventos/detalle/' },
      { name: 'Calendario', url: '/eventos/calendario' },
    ],
    Contacto: [{ name: 'Contacto', url: '/contacto' }],
  };

  constructor() {}

  ngOnInit(): void {}
}
