import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-aliados',
  templateUrl: './aliados.component.html',
  styleUrls: ['./aliados.component.scss'],
})
export class AliadosComponent implements OnInit, AfterViewInit {
  @ViewChild('scroller') scroller: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    var timer = setInterval(() => {
      var offsset = this.scroller.nativeElement.offsetWidth / 4 + 17;
      this.scroller.nativeElement.scrollLeft = offsset;
      clearInterval(timer);
    }, 500);
  }
}
