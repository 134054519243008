import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CoreService } from '../core.service';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(300)]),
      transition(':leave', [animate(500)]),
    ]),
    trigger('fadeLogo', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2500, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class NavComponent implements OnInit {
  navIsCms = false;
  navIsNotLandingPage = false;
  routes = [];
  homeRoute = '/';
  regularRoutes = [
    {
      routerLink: '/cursos',
      name: 'Cursos y Diplomados',
    },
    {
      routerLink: '/nosotros',
      name: 'Nosotros',
    },
    {
      routerLink: '/eventos',
      name: 'Eventos',
    },
    {
      routerLink: '/contacto',
      name: 'Contacto',
    },
  ];

  cmsRoutes = [
    {
      routerLink: '/cms/cursos',
      name: 'Cursos',
    },
    {
      routerLink: '/cms/instalaciones',
      name: 'Instalaciones',
    },
    {
      routerLink: '/cms/eventos',
      name: 'Eventos',
    },
    {
      routerLink: '/cms/ayuda',
      name: 'Ayuda',
    },
  ];

  constructor(private router: Router, private core: CoreService) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const ev: any = event;
        this.navIsNotLandingPage = !(ev.url === '/');
        this.navIsCms = ev.url.includes('/cms');
        if (this.navIsCms) {
          this.routes = this.cmsRoutes;
          this.homeRoute = '/cms/cursos';
        } else {
          this.routes = this.regularRoutes;
          this.homeRoute = '/';
        }
      });
  }

  ngOnInit(): void {}

  signOut() {
    this.core.signOut();
    this.router.navigate(['/']);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('.navbar');
    let logo = document.querySelector('.logo');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('sticky');
      logo.classList.add('logo-small');
    } else {
      element.classList.remove('sticky');
      if (!this.navIsNotLandingPage) {
        logo.classList.remove('logo-small');
      }
    }
  }
}
