import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { SiteMapComponent } from './site-map/site-map.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    component: InicioComponent,
  },
  {
    path: 'cursos',
    loadChildren: () =>
      import('./cursos-diplomados/cursos-diplomados.module').then(
        (mod) => mod.CursosDiplomadosModule
      ),
  },
  {
    path: 'nosotros',
    loadChildren: () =>
      import('./nosotros/nosotros.module').then((mod) => mod.NosotrosModule),
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('./eventos/eventos.module').then((mod) => mod.EventosModule),
  },
  {
    path: 'contacto',
    loadChildren: () =>
      import('./contacto/contacto.module').then((mod) => mod.ContactoModule),
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then((mod) => mod.CmsModule),
  },
  {
    path: 'aviso-privacidad',
    component: AvisoPrivacidadComponent,
  },
  {
    path: 'terminos-condiciones',
    component: TerminosCondicionesComponent,
  },
  {
    path: 'sitemap',
    component: SiteMapComponent,
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
