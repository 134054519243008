<h1>Términos y Condiciones</h1>

<h2>Términos generales</h2>
<p>
  Al accesar a este sitio web a través de cualquier instrumento o dispositivo de
  consulta (navegador) implica el apego a los presentes términos y condiciones
  de uso, por lo que todo visitante, quedará sujeto a ellos.
</p>

<h2>Contenido del sitio web</h2>
<p>
  El contenido de este sitio web tiene carácter informativo y queda sujeto a
  modificaciones y actualizaciones periódicas por parte de Instituto de
  desarrollo Profesional y Técnico AC, que se podrán implementar sin previo
  aviso a los usuarios. Instituto de desarrollo Profesional y Técnico AC no se
  responsabiliza, en ningún caso, de los daños y perjuicios de cualquier
  naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones
  en los contenidos, falta de disponibilidad del portal o la transmisión de
  virus o programas maliciosos o lesivos en los contenidos, a pesar de haber
  adoptado todas las medidas tecnológicas necesarias para evitarlo.
</p>

<h2>Cookies</h2>
<p>
  Este sitio web utiliza Google Analytics, un servicio de análisis de la red
  Google, Inc. ("Google"). Google Analytics utiliza "cookies", los cuales son
  archivos de texto que se colocan en su ordenador o computadora para ayudar a
  que la página web analice la manera en que los usuarios utilizan la página. La
  información generada por los cookies sobre cómo usted utiliza la página web es
  transmitida y almacenada por Google en sus servidores. Google utilizará esta
  información con el propósito de evaluar su utilización de la página web,
  compilando informes con las actividades en el sitio web para las operadoras de
  la página web y suministrando otros servicios que se relacionan con las
  actividades en la página web y el uso de Internet. Una Cookie no puede leer
  los datos o información del disco duro del usuario ni leer las Cookies creadas
  por otros sitios o páginas.
</p>

<h2>Vínculos con otros sitios</h2>
<p>
  Este sitio web contiene links que lo conectan a otros Sitios Web. Estos links
  son proporcionados solamente para su comodidad, esto no quiere decir que los
  contenidos de estos Sitios Web, sean responsabilidad de Instituto de
  desarrollo Profesional y Técnico AC. Instituto de desarrollo Profesional y
  Técnico AC no es responsable del contenido de estos links y del contenido o
  precisión del material de dichos sitios Web. Si usted decide accesar a los
  links de estos sitios, es bajo su propio riesgo.
</p>

<h2>Servicios</h2>
<p>
  Cada servicio cuenta con características únicas que pueden variar de cliente a
  cliente y según los requerimientos que cada uno nos solicita. Todos nuestros
  servicios son ofrecidos para el desempeño de actividades lícitas, dentro de un
  marco legal nacional e internacional, por lo que el uso indebido de cualquiera
  de nuestros servicios en actividades ilícitas automáticamente suspende el
  servicio sin responsabilidad para nuestra empresa y sin devoluciones por pagos
  anticipados o devengados.
</p>

<h2>Pago de servicios</h2>
<p>
  • Para recibir y conservar un producto o la prestación de un servicio de
  Instituto de desarrollo Profesional y Técnico AC, el cliente debe realizar el
  pago correspondiente con puntualidad a través de uno de los medios que
  Instituto de desarrollo Profesional y Técnico AC haya informado por medio de
  correo electrónico para la recepción de pagos.
</p>
<p>
  • Los servicios que ameritan pagos recurrentes o suscripciones, deberán
  realizarse antes de la fecha límite establecida a fin de continuar con el
  servicio sin interrupciones. Instituto de desarrollo Profesional y Técnico AC
  estará en su derecho de suspender o cancelar la prestación de un servicio una
  vez vencido el plazo de pago.
</p>

<h2>Certificación</h2>
<p>
  La entrega de constancias se realizara en tiempo y forma marcados por el
  Instituto de desarrollo Profesional y Técnico AC una vez el estudiante liquide
  cualquier adeudo y entregue la papelería solicitada, es necesario en todo
  momento que el estudiante muestre la evidencia de no adeudo y se concluya el
  programa de capacitación en su totalidad.
</p>

<h2>Horarios</h2>
<p>Lunes a Viernes de 9:00 a 18:00 hrs.</p>
<p>Sábados de 9:00 a 14:00 hrs.</p>

<h2>Urgencias</h2>
<p>
  Todos los requerimientos especiales y fuera de nuestras instalaciones u
  horarios generarán un cargo adicional de acuerdo al servicio en el momento de
  su generación.
</p>

<h2>Modificación de los presentes términos y condiciones</h2>
<p>
  Instituto de desarrollo Profesional y Técnico AC podrá modificar en cualquier
  momento las condiciones aquí determinadas, siendo debidamente publicadas como
  aquí aparecen.
</p>
