import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CoreService } from '../core.service';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-nav-movil',
  templateUrl: './nav-movil.component.html',
  styleUrls: ['./nav-movil.component.scss']
})
export class NavMovilComponent implements OnInit {
  navIsCms = false;
  navIsNotLandingPage = false;
  routes = [];
  homeRoute = '/';
  regularRoutes = [
    {
      routerLink: 'home',
      name: 'Inicio',
      icon: 'home'
    },
    {
      routerLink: '/cursos',
      name: 'Cursos',
      icon: 'book'
    },
    {
      routerLink: '/nosotros',
      name: 'Nosotros',
      icon: 'people_alt'
    },
    {
      routerLink: '/eventos',
      name: 'Eventos',
      icon: 'calendar_today'
    },
    {
      routerLink: '/contacto',
      name: 'Contacto',
      icon: 'email'
    },
  ];

  cmsRoutes = [
    {
      routerLink: '/cms/cursos',
      name: 'Cursos',
      icon: 'book'
    },
    {
      routerLink: '/cms/instalaciones',
      name: 'Instalaciones',
      icon: 'domain'
    },
    {
      routerLink: '/cms/eventos',
      name: 'Eventos',
      icon: 'calendar_today'
    },
    {
      routerLink: '/cms/ayuda',
      name: 'Ayuda',
      icon: 'email'
    },
  ];

  constructor(private router: Router, private core: CoreService) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const ev: any = event;
        this.navIsCms = ev.url.includes('/cms');
        if (this.navIsCms) {
          this.routes = this.cmsRoutes;
          this.homeRoute = '/cms/cursos';
        } else {
          this.routes = this.regularRoutes;
          this.homeRoute = '/';
        }
      });
  }

  ngOnInit(): void {}

  signOut() {
    this.core.signOut();
    this.router.navigate(['/']);
  }

}
