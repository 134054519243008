import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Curso, Evento, Instalacion } from './modelo';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private coleccionCursos: AngularFirestoreCollection<Curso>;
  private coleccionEventos: AngularFirestoreCollection<Evento>;
  private coleccionInstalaciones: AngularFirestoreCollection<Instalacion>;

  cursos$: Observable<Curso[]>;
  instalaciones$: Observable<Instalacion[]>;
  eventos$: Observable<Evento[]>;
  private cursosSubject: BehaviorSubject<any>;
  private instalacionesSubject: BehaviorSubject<any>;
  private eventosSubject: BehaviorSubject<any>;

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth,
    private fireFunctions: AngularFireFunctions
  ) {
    this.cursosSubject = new BehaviorSubject(null);
    this.cursos$ = this.cursosSubject.asObservable();
    this.instalacionesSubject = new BehaviorSubject(null);
    this.instalaciones$ = this.instalacionesSubject.asObservable();
    this.eventosSubject = new BehaviorSubject(null);
    this.eventos$ = this.eventosSubject.asObservable();
  }

  // CURSOS
  getCursosFirebase() {
    if (this.cursosSubject.getValue() === null) {
      this.coleccionCursos = this.firestore.collection<Curso>(
        'cursos_diplomados'
      );
      this.coleccionCursos
        .snapshotChanges()
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Curso;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        )
        .subscribe((cursos) => {
          this.setCursos(cursos);
        });
    }
  }

  setCursos(cursos) {
    this.cursosSubject.next(cursos);
  }

  getCurso(id) {
    const cursos = this.cursosSubject.getValue();
    return cursos.filter((curso) => curso.id === id)[0];
  }

  // EVENTOS
  getEventosFirebase() {
    if (this.eventosSubject.getValue() === null) {
      this.coleccionEventos = this.firestore.collection<Evento>('eventos');
      this.coleccionEventos
        .snapshotChanges()
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Evento;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        )
        .subscribe((eventos) => {
          this.setEventos(eventos);
        });
    }
  }

  setEventos(eventos) {
    this.eventosSubject.next(eventos);
  }

  getEvento(id) {
    const eventos = this.eventosSubject.getValue();
    return eventos.filter((evento) => evento.id === id)[0];
  }

  // INSTALACIONES
  getInstalacionesFirebase() {
    if (this.instalacionesSubject.getValue() === null) {
      this.coleccionInstalaciones = this.firestore.collection<Instalacion>(
        'instalaciones'
      );
      this.coleccionInstalaciones
        .snapshotChanges()
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Instalacion;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        )
        .subscribe((instalaciones) => {
          this.setInstalaciones(instalaciones);
        });
    }
  }

  setInstalaciones(instalaciones) {
    this.instalacionesSubject.next(instalaciones);
  }

  getInstalacion(id) {
    const instalaciones = this.instalacionesSubject.getValue();
    return instalaciones.filter((instalacion) => instalacion.id === id)[0];
  }

  async signOut() {
    try {
      await this.auth.signOut();
      return true;
    } catch (error) {
      console.log('Sign out failed', error);
      return false;
    }
  }

  correoContacto(correo) {
    const callable = this.fireFunctions.httpsCallable('contactMail');
    return callable(correo);
  }

  correoRegistro(correo) {
    const callable = this.fireFunctions.httpsCallable('registerMail');
    return callable(correo);
  }

  correoAyudaTecnica(correo) {
    const callable = this.fireFunctions.httpsCallable('helpMail');
    return callable(correo);
  }
}
