import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/global-data';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [Globals],
})
export class FooterComponent implements OnInit {
  constructor(public globals: Globals) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  mailto() {
    var email = 'instituto@benengeli.com';
    location.href = 'mailto:' + email;
  }

  openInMaps() {
    var link ='https://goo.gl/maps/1YMCZF1Us9FGejHD7';
    window.open(link, "_blank");
  }
}
