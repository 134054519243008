<header
  class="animated fadeInDown navbar"
  [class.small-nav]="navIsNotLandingPage"
  (scroll)="onWindowScroll($event)"
>
  <div [routerLink]="homeRoute">
    <img
      @fadeLogo
      class="logo"
      [class.logo-big]="!navIsNotLandingPage"
      [class.logo-small]="navIsNotLandingPage"
      src="../../../assets/images/benengeliLogo.png"
      alt="Logo Instituto Cide Hamet Benengeli"
    />
    <h1 class="title">Instituto Benengeli</h1>
  </div>
  <nav>
    <ul class="nav-links">
      <li *ngFor="let route of routes">
        <a [routerLink]="route.routerLink" routerLinkActive="selected">{{
          route.name
        }}</a>
      </li>
      <li *ngIf="navIsCms">
        <a (click)="signOut($event)">Cerrar Session</a>
      </li>
    </ul>
  </nav>
  <app-buscador class="buscador" *ngIf="!navIsCms"></app-buscador>
</header>
