<h1>Aviso de Privacidad</h1>
<p>
  Instituto de Desarrollo Profesional y Técnico AC es responsable de recabar sus
  datos personales, del uso que se le dé a los mismos y de su protección. Su
  información personal será utilizada para proveer los servicios y productos que
  ha solicitado, informarle sobre cambios en los mismos y evaluar la calidad del
  servicio que le brindamos. Para las finalidades antes mencionadas, requerimos
  obtener los siguientes datos personales: Nombre, Teléfono, E-mail considerado
  como sensible según la Ley Federal de Protección de Datos Personales en
  Posesión de los Particulares. Usted tiene derecho de acceder, rectificar y
  cancelar sus datos personales, así como de oponerse al tratamiento de los
  mismos o revocar el consentimiento que para tal fin nos haya otorgado, a
  través de los procedimientos que hemos implementado. Para conocer dichos
  procedimientos, los requisitos y plazos, se puede poner en contacto con
  nuestro departamento de datos personales en instituto.benengeli@ideprotec.com
</p>
<p>
  Así mismo, le informamos que sus datos personales pueden ser transferidos y
  tratados dentro y fuera del país, por personas distintas a esta empresa. En
  ese sentido, su información puede ser compartida con colaboradores o coaches
  de esta misma institución para preparar propuestas o proyectos en conjunto. Si
  usted no manifiesta su oposición para que sus datos personales sean
  transferidos, se entenderá que ha otorgado su consentimiento para ello.
</p>
<p>
  Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede
  solicitarlo a través de instituto.benengeli@ideprotec.com
</p>
<p>
  modificación a este aviso de privacidad podrá consultarla en Aviso de
  Privacidad
</p>

<p>Fecha última actualización Mayo 2017.</p>
