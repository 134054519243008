<nav class="nav">
  <a
    *ngFor="let route of routes"
    [routerLink]="route.routerLink"
    routerLinkActive="selected"
    class="nav__link"
  >
    <i class="material-icons nav__icon">{{ route.icon }}</i>
    <span class="nav__text">{{ route.name }}</span>
  </a>
  <a class="nav__link" *ngIf="navIsCms" (click)="signOut($event)">
    <i class="material-icons nav__icon">exit_to_app</i>
    <span class="nav__text">Cerrar Sesión</span></a
  >
</nav>
