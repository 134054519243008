<footer>
  <div class="identidad">
    <h1>Instituto Cide Hamet Benengeli</h1>
    <span>© 2010 — 2020</span>
    <br />
    <a routerLink="/aviso-privacidad">Aviso de privacidad</a> —
    <a routerLink="/terminos-condiciones">Términos y condiciones</a>
    <br />
    <span class="sep-aviso">
      Avalado por la Secretaria de Educacion
      <img src="/assets/images/sep-logo.png" alt="" />
    </span>
  </div>
  <div class="navegacion-footer">
    <div class="contacto">
      <h4>Contacto</h4>
      <ul>
        <li>
          <i class="fa fa-phone" aria-hidden="true"></i>
          {{ globals.informacionDeContacto.telefono }}
        </li>
        <li (click)="mailto()" id="correo">
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
          {{ globals.informacionDeContacto.correo }}
        </li>
        <li (click)="openInMaps()" id="mapa">
          <p>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            {{ globals.informacionDeContacto.direccion.calle }}
            <br />
            {{ globals.informacionDeContacto.direccion.colonia }}
            <br />
            {{ globals.informacionDeContacto.direccion.cp }}
          </p>
        </li>
      </ul>
    </div>
    <div class="sitio">
      <h4>Sitio</h4>
      <div class="navegacion-sitio">
        <ul class="columna-izq">
          <li><a routerLink="/cursos">Cursos y Diplomados</a></li>
          <li><a routerLink="/nosotros">Nosotros</a></li>
          <li><a routerLink="/nosotros/instalaciones">Instalaciones</a></li>
          <li><a routerLink="/eventos">Eventos</a></li>
        </ul>
        <ul class="columna-der">
          <li><a routerLink="/contacto">Contacto</a></li>
          <li><a routerLink="/eventos/calendario">Calendario</a></li>
          <li><a routerLink="/sitemap">Sitemap</a></li>
          <li><a routerLink="/cms">CMS</a></li>
        </ul>
      </div>
    </div>
    <div>
      <h4>Siguenos en redes</h4>
      <div class="redes">
        <a href="https://www.facebook.com/benengelimonterrey"
          ><i class="fa fa-facebook" aria-hidden="true"></i
        ></a>
        <a href="https://www.youtube.com/channel/UCNJzyiEGzotnfpz3H_UGY5w"
          ><i class="fa fa-youtube-play" aria-hidden="true"></i
        ></a>
        <a href="https://www.instagram.com/instituto_benengeli/"
          ><i class="fa fa-instagram" aria-hidden="true"></i
        ></a>
        <a href="https://www.linkedin.com/in/instituto-benengeli-2a66bb9a/"
          ><i class="fa fa-linkedin" aria-hidden="true"></i
        ></a>
      </div>
    </div>
    <div class="credito">
      Diseñado y Desarrollado por <a href="http://carlosgzz.com/">Carlos Gzz</a>
    </div>
  </div>
</footer>
