// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBHVtZyirzqUhJlkobKUOwlAty_WDDmwvg",
    authDomain: "instituto-benengeli.firebaseapp.com",
    databaseURL: "https://instituto-benengeli.firebaseio.com",
    projectId: "instituto-benengeli",
    storageBucket: "instituto-benengeli.appspot.com",
    messagingSenderId: "702243212800",
    appId: "1:702243212800:web:d14396fdf23c573d1dd2e5",
    measurementId: "G-RZ214PR600"
  },
  googleMaps: {
    apiKey: 'AIzaSyABJJWGatupWAD1fMHFhtLrWb31PrC0R34'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
