import { Component, OnInit } from '@angular/core';
import { IImage } from '../../../node_modules/ng-simple-slideshow/src/app/modules/slideshow/IImage';
import {
  SlideInOutAnimationHorizontal,
  FadeInOutAnimation,
} from 'src/app/core/animations';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  animations: [SlideInOutAnimationHorizontal, FadeInOutAnimation],
})
export class InicioComponent implements OnInit {
  imageUrls: (string | IImage)[] = [
    {
      url: '/assets/images/inicio_slider_1.jpg',
      caption: 'Curso de Direccion y Administracion de Seguridad',
    },
    {
      url: '/assets/images/inicio_slider_2.jpg',
      caption: 'Curso de Direccion y Administracion de Seguridad',
    },
    {
      url: '/assets/images/inicio_slider_3.jpg',
      caption: 'Curso de Tiro',
    },
    {
      url: '/assets/images/inicio_slider_4.jpg',
      caption: 'Curso de Poligrafo',
    },
    {
      url: '/assets/images/inicio_slider_5.jpg',
      caption: 'Curso de Prevencion de Incendios',
    },
    {
      url: '/assets/images/inicio_slider_6.jpg',
      caption: 'Curso de Direccion y Administracion de Seguridad',
    },
    {
      url: '/assets/images/inicio_slider_7.jpg',
      caption: 'Curso de Direccion y Administracion de Seguridad',
    },
  ];
  height: string = '60VH';
  minHeight: string;
  arrowSize: string = '15px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 7777;
  stopAutoPlayOnSlide: boolean = true;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = false;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = true;
  hideOnNoSlides: boolean = true;
  width: string = '100%';
  fullscreen: boolean = false;
  enableZoom: boolean = true;
  enablePan: boolean = false;
  noLoop: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  testEvent(event) {
    console.log(typeof event);
    console.log(event);
  }
}
