<div class="aliados">
  <h2>Aliados</h2>
  <div class="container">
    <img
      class="aliados-logo"
      src="/assets/images/uscc_logo_aliados.png"
      alt="Logotipo de Aliado Universidad de Ciencias de la Seguridad"
    />
    <img
      class="aliados-logo circular"
      src="/assets/images/ampac.png"
      alt="Logotipo de Aliado Asociación Mexicana de Profesionales en Prevención de Pérdidas"
    />
    <img
      class="aliados-logo circular"
      src="/assets/images/asisInternational.png"
      alt="Logotipo de Aliado Sociedad Americana de Seguridad Industrial"
    />
  </div>
</div>
<div class="aliados-movil">
  <h2>Aliados</h2>
  <div class="scrolling-wrapper" #scroller>
    <div class="card">
      <img
        class="aliados-logo"
        src="/assets/images/uscc_logo_aliados.png"
        alt="Logotipo de Aliado Universidad de Ciencias de la Seguridad"
      />
      <img
        class="aliados-logo circular"
        src="/assets/images/ampac.png"
        alt="Logotipo de Aliado Asociación Mexicana de Profesionales en Prevención de Pérdidas"
      />
      <img
        class="aliados-logo circular"
        src="/assets/images/asisInternational.png"
        alt="Logotipo de Aliado Sociedad Americana de Seguridad Industrial"
      />
    </div>
  </div>
</div>
