import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss'],
})
export class BuscadorComponent implements OnInit {
  busqueda = null;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  buscar(event) {
    if(event === null && this.busqueda !== null) {
      this.router.navigate(['/cursos/resultados', this.busqueda]);
    }else {
      event.preventDefault();
      const busqueda = event.srcElement.value;
      this.router.navigate(['/cursos/resultados', busqueda]);
    }
  }
}
